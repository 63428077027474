<template>
  <div class="container">
    <el-form :model="appForm" label-width="140px" @submit.prevent="submitApp">
      <el-row>
        <el-col :span="12">
          <el-form-item label="应用名称" prop="name">
            <el-input v-model="appForm.name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="应用负责人" prop="manager">
            <el-select v-model="appForm.manager_id" placeholder="请选择" style="width: 120px">
              <template v-for="item in managerlist">
                <el-option :key="item.id" :label="item.name" :value="item.id"></el-option>
              </template>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="图片">
        <el-upload
                class="avatar-uploader"
                name="image_file"
                :action="uploadUrl"
                :headers="headerObj"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload">
          <img v-if="imageUrl" :src="imageUrl" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <span style="color:#d0d0d0;">支持jpg，png格式照片，最大2M</span>
      </el-form-item>
      <el-row>
        <el-col :span="12">
          <el-form-item label="应用范围" prop="app_range">
            <el-input v-model="appForm.app_range"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="开发语言" prop="language">
            <el-input v-model="appForm.language"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="开发商" prop="developer">
            <el-input v-model="appForm.developer"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="系统状态" prop="sys_status">
            <el-input v-model="appForm.sys_status"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="信创适配情况" prop="made_inchina">
            <el-input v-model="appForm.made_inchina"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="资金投入" prop="price">
            <el-input v-model="appForm.price"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="维护部门" prop="main_depart">
            <el-input v-model="appForm.main_depart"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="使用部门" prop="use_depart">
            <el-input v-model="appForm.use_depart"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="备注" prop="note">
        <el-input v-model="appForm.note" type="textarea" :rows="3"></el-input>
      </el-form-item>
      <div style="margin-top:30px; text-align: center">
        <el-button type="primary" native-type="submit" @click.prevent="submitApp">提交</el-button>
        <el-button @click="closePop">关闭</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
  export default {
    name: "AppForm",
    props: {
      id: {
        type: Number
      },
    },
    data() {
      return {
        appForm: {
          name: '',
          manager_id: '',
          app_range: '',
          language: '',
          developer: '',
          sys_status: '',
          made_inchina: '',
          price: '',
          main_depart: '',
          use_depart: '',
          note: '',
          pic: ''
        },
        managerlist: [],
        uploadUrl: this.$api.BaseUrl + this.$api.UploadFile,
        headerObj: {
          'access-token': sessionStorage.getItem('access-token')
        },
        imageUrl: '',
      }
    },
    watch: {
      id(val, oldVal) {
        if (val != oldVal && val != null) {
          this.getInfo();
        }
        if (val === null) {
          this.form = {};
        }
      }
    },
    mounted() {
      if (this.id != null) {
        this.getInfo();
      }
    },
    created() {
      this.getManagerList();
    },
    methods: {
      getManagerList() {
        this.$get(this.$api.GetManagerList).then((res) => {
          this.managerlist = res.data.list;
        });
      },
      getInfo() {
        this.$get(this.$api.GetAppInfo, {id: this.id}).then((res) => {
          this.appForm = res.data;
          this.imageUrl = res.data.pic;
        });
      },
      submitApp() {
        if (this.appForm.name == '') {
          this.$message.warning('名称不能为空');
          return false;
        }
        let postUrl = this.$api.AddApp;
        if (this.id != null) {
          postUrl = this.$api.EditApp;
        }

        this.$post(postUrl, this.appForm).then(() => {
          this.$message({
            message: '操作成功！',
            type: 'success'
          });
          this.closePop('refresh');
        });
      },
      closePop(param) {
        this.$emit('closePop', param);
      },
      handleAvatarSuccess(res, file) {
        this.imageUrl = URL.createObjectURL(file.raw);
        this.appForm.pic = res.data.filename;
      },
      beforeAvatarUpload(file) {
        if (file.size / 1024 / 1024 > 2) {
          this.$message.error('上传头像图片大小不能超过 2MB!');
          return false;
        }

        if (file.type === 'image/jpeg' || file.type === 'image/png') {
          return;
        } else {
          this.$message.error('上传头像图片只能是 JPG或PNG 格式!');
          return false;
        }
      },
    }
  }
</script>

<style scoped lang="less">
  .avatar-uploader {
    width: 180px;
    height: 100px;
    border: 1px dashed #1E75BD;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 180px;
      height: 100px;
      line-height: 100px;
      text-align: center;
    }
    .avatar {
      width: 180px;
      height: 100px;
    }
  }
  .avatar-uploader:hover {
    border-color: #409EFF;
  }
</style>
